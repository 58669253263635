import React from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const PercentageDialog =(props)=> {

    const aColumns =[
        {label: "TotalValue"},
        {label: "CurrentValue"}
    ]

    /** Update the Input data
     * 
     * @param {*} oEvent 
     */
    const handleChangeEvent =(oEvent)=> {
        const { value,name } = oEvent.target;
        const oUpdatedRows = props.oData.Percentage;
        oUpdatedRows[name] = value;
        props.updateData({ ...props.oData, Percentage: oUpdatedRows });
    }

    var aTempSelectControl = [];
    if (props.inputFields) {
      for (var i = 0; i < aColumns.length; i++) {
        aTempSelectControl.push(
          <div id={"idField" + i}>
            <br></br>
            <TextField
              select
              id={"idG_GanntPercent" + i}
              label={aColumns[i].label}
              sx={{ minWidth: 450 }}
              onChange={(oEvent) => {
                handleChangeEvent(oEvent);
              }}
              name={aColumns[i].label}
              defaultValue={
                props.oData ? props.oData.Percentage[aColumns[i].label] : ""
              }
            >
              {props.inputFields.map((oField) => (
                <MenuItem key={oField} name={oField} value={oField}>
                  {oField}
                </MenuItem>
              ))}
            </TextField>
          </div>
        );
      }
    }

    return(
        <>
            {aTempSelectControl}
        </>
    )
}