import React from "react";
import "./MenuBar.css";

//Material UI
import Stack from "@mui/material/Stack";
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import Switch from '@mui/material/Switch';

export const MenuBar = (props) => {
  function handlePageClick(oPage) {
    props.selectPage(oPage);
  }

  function handleLogicClick(oLogic) {
    props.onSelectLogic(oLogic);
  }

  function handleValidationClick(oValidationLogic) {
    props.onSelectValidationLogic(oValidationLogic);
  }
  return (
    <div className="menuBar-title-control">
      <div>
        <Stack direction="row" spacing={1}>
          <div>
            <label
              className="menuBar-title-Label"
              onClick={(oEvent) => {
                handlePageClick(props.page);
              }}
            >
              {props.page["name"]}
            </label>
            <strong className="label-separator">
              {props.logic["label"] ? "/ " : ""}
            </strong>
            <label
              className="menuBar-title-Label"
              onClick={() => {
                handleLogicClick(props.logic);
              }}
            >
              {props.logic["label"] ? props.logic["label"] : ""}
            </label>
            <strong className="label-separator">
              {props.validation["label"] ? "/ " : ""}
            </strong>
            <label
              className="menuBar-title-Label"
              onClick={(oEvent) => {
                handleValidationClick(props.validation);
              }}
            >
              {props.validation["label"] ? props.validation["label"] : ""}
            </label>
          </div>
        </Stack>
      </div>
      <div className="menuBar-title-container">
        <div
          className="menuBar-title-delete"
          onClick={props.multiDelete}
        >
          DELETE
        </div>
        <div
          className="menuBar-title-update"
          onClick={() => props.updateComponentProperty(true)}
        >
          UPDATE
        </div>
        <div
          className="menuBar-title-update"
          onClick={() => props.updateAllComponentsProperty(true)}
        >
          UPDATEALL
        </div>
        <div class="menuBar-title-mobile">
          <Switch
            checked={props.isMobileSize}
            onChange={props.handleChangeToggle}
            inputProps={{ "aria-label": "controlled" }}
            size="small"
          />
        </div>
        <div class="menuBar-title-wizard" onClick={()=>props.handleOpenWizard(true)}>Wizard</div>
        <div className="menuBar-title-alignment" onClick={props.componentAlign}>
          <FormatAlignLeftOutlinedIcon />
        </div>
      </div>
    </div>
  );
};
