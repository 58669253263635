import Server from "../../../../../serverDetails/server";

export const DeleteDB = (
  aProperties,
  oParams,
  aPromises,
  stopLoop,
  oLogicDatas
) => {
  let sAdminProperty = false;

  var sTableName = aProperties.find(function (oProperty) {
    return oProperty.id === "idDeleteDBName";
  }).value;

  var bMultiDelete = aProperties.find(function (oProperty) {
    return oProperty.id === "idMultiDelete";
  })?.value;

  var aConditionProperty = aProperties.find((oProperty) => {
    return oProperty.id === "idDeleteCondition";
  }).value;

  if (localStorage.getItem("ADMIN")) {
    sAdminProperty = aProperties.find(function (oProperty) {
      return oProperty.id === "idAdmin";
    })?.value;
  }

  var bSubArray = false;
  var oDeleteCondition;
  if (bMultiDelete) {
    oDeleteCondition = aConditionProperty.map(function (oCondition) {
      const aValues = oParams
        .map((aData) => {
          const aArrayValues = aData.find(
            (item) => item.field === oCondition.value
          );
          return aArrayValues ? aArrayValues.value : null;
        })
        .filter((id) => id !== null);

      if (oCondition.subfield !== "" && oCondition.subfield !== undefined) {
        bSubArray = true;
      }
      return {
        field: oCondition.field,
        subfield: oCondition.subfield,
        operator: oCondition.operator,
        value: aValues ? aValues : null,
        dataType: oCondition.dataType,
      };
    });
  } else {
    oDeleteCondition = aConditionProperty.map(function (oCondition) {
      var matchingValue = oParams.find(function (oValue) {
        return oCondition.value === oValue.field;
      });
      if (oCondition.subfield !== "" && oCondition.subfield !== undefined) {
        bSubArray = true;
      }
      return {
        field: oCondition.field,
        subfield: oCondition.subfield,
        operator: oCondition.operator,
        value: matchingValue ? matchingValue.value : null,
        dataType: oCondition.dataType,
      };
    });
  }

  const operatorMap = {
    Equal: "$in",
    "Not Equal": "$nin",
    "Less Than": "$lt",
    "Greater Than": "$gt",
  };
  var oWhere;

  if (!bSubArray && oDeleteCondition.length > 0) {
    const conditions = [];

    for (const condition of oDeleteCondition) {
      const { field, operator, value, dataType } = condition;
      let updatedValue;

      const parseDate = (dateValue) => {
        const date = new Date(dateValue);
        return date;
      };

      if (bMultiDelete && Array.isArray(value)) {
        updatedValue = value.map((val) => {
          if (dataType === "Number") {
            return parseInt(val, 10);
          } else if (dataType === "Date") {
            return parseDate(val);
          } else {
            return val;
          }
        });
      } else {
        if (dataType === "Number") {
          updatedValue = parseInt(value, 10);
        } else if (dataType === "Date") {
          updatedValue = parseDate(value);
        } else {
          updatedValue = value;
        }
      }

      if (!bMultiDelete && (operator === "Equal" || operator === "Not Equal")) {
        updatedValue = [updatedValue];
      }
      const whereCondition = {
        [field]: { [operatorMap[operator]]: updatedValue },
      };
      conditions.push(whereCondition);
    }

    oWhere = { $and: conditions };
  } else {
    const dDate = (value) => {
      const date = new Date(value);
      return date;
    };

    oDeleteCondition.forEach((element) => {
      if (element.dataType === "Number") {
        element.value = parseInt(element.value, 10);
      } else if (element.dataType === "Date") {
        element.value = dDate(element.value);
      }
    });
  }

  var odeleteData = {
    TABLE_NAME: sTableName,
    WHERE: oWhere,
    bSubArray,
    oDeleteCondition,
    ADMINAPP: sAdminProperty,
    APP_ID: oLogicDatas.APPID,
  };

  oLogicDatas.bBusyIndicator(true);
  aPromises.push(
    new Promise((resolve) => {
      Server.delete(`/logics/db/delete`, {
        data: odeleteData,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
        .then(function (oResult) {
          oLogicDatas.bBusyIndicator(false);
          if (oResult.status === 201) {
            resolve(oResult.data);
          } else {
            oLogicDatas.iErrorCode(oResult.status);
            oLogicDatas.sErrorMessage(oResult.data.message);
            oLogicDatas.bSnackBar(true);
          }
        })
        .catch(function (oError) {
          oLogicDatas.bBusyIndicator(false);
          oLogicDatas.iErrorCode(oError.response.status);
          oLogicDatas.sErrorMessage(oError.response.data.message);
          oLogicDatas.bSnackBar(true);
        });
    })
  );
};
